import router from '@/router'
import { getChannel } from '@/js/utils'
import axios from 'axios'

const campLejeuneSettlement = {
  namespaced: true,
  actions: {
    goToNextStep({ rootState, getters, commit, dispatch }, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''

      switch(step) {
        case 'StartPath': {
          nextStep = 'ServedAtCampLejeune'
          break
        }

        case 'ServedAtCampLejeune': {
          nextStep = 'SelectMedicalCondition'
          break
        }

        case 'SelectMedicalCondition' : {
          nextStep = 'HasAttorney'
          break
        }

        case 'HasAttorney' : {
          nextStep = 'CollectPII'
          break
        }

        case 'CollectPII' : {
          dispatch('packageLeads')
          break
        }
      }

      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },
    packageLeads({ rootState, getters, commit, dispatch }) {
      const params = new URLSearchParams(location.search)
      const { fields } = rootState

      const data = {
        ...fields,
        affid: params['affid'] || 1,
        lp_campaign_id: params['lp_campaign_id'] || fields.campaignId || '7402',
        lp_supplier_id: params['lp_supplier_id'] || fields.supplierId || '15116',
        lp_key: params['lp_key'] || fields.key || '7drtmvriwryx',
        gclid: params['gclid'] || '',
        fbclid: params['fbclid'] || '',
        oid: fields['oid'],
        ip_address: fields.ipAddress,
        channel: getChannel(),
        trusted_form_cert_id: fields.trustedFormId,
        trusted_form_cert_url: fields.trustedFormUrl,
        user_agent: 'userAgent' in navigator ? navigator.userAgent : ''
      }

      const keysToDelete = [
        'campaignId',
        'supplierId',
        'key',
        'ipAddress',
        'trustedFormId',
        'trustedFormUrl',
        '_ef_transaction_id',
      ]

      keysToDelete.forEach(key => {
        delete data[key]
      })

      dispatch('sendLeads', data)
    },
    async sendLeads({ rootState }, leads) {
      await axios.post('https://control.org31415.dev/api/leads/ingest', leads)

      const { fields } = rootState
      const qualified = (fields.has_lawyer === 'no' && fields.on_base_between_1953_and_1987 === 'yes' && fields.disease !== 'none of the above') ? 'y': 'n'

      await router.push({
        path: '/thank-you-cj',
        query: {
          ef_transaction_id: fields['ef_transaction_id'],
          affid: fields['affid'] || '1',
          sub_id: fields['sub_id'] || '1',
          page: fields['landing_page'],
          q: qualified
        }
      })
    }
  }
}

export default campLejeuneSettlement