import { extractUrlParams, getChannel } from '@/js/utils'
import axios from 'axios'

const mva = {
  namespaced: true,
  getters: {
    getQualified(state, getters, rootState) {
      return (
        (
          rootState.fields.accident_limit_exceeded === 0 &&
          rootState.fields.physical_injury === 'Yes' &&
          rootState.fields.with_Lawyer === 'no' &&
          rootState.fields.at_Fault === 'no' &&
          rootState.fields.name_gibberish === 'no' &&
          rootState.fields.name_bad_word === 'no'
        ) ? 'yes' : 'no'
      )
    },
  },
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''

      switch(step) {
        case 'StartPath': {
          nextStep = 'WasUserInjured'
          break
        }

        case 'WasUserInjured': {
          nextStep = 'AtFault'
          break
        }

        case 'AtFault': {
          nextStep = 'HasLawyer'
          break
        }

        case 'HasLawyer': {
          nextStep = 'PoliceReport'
          break
        }

        case 'PoliceReport': {
          nextStep = 'SelectDateMVA'
          break
        }

        case 'SelectDateMVA': {
          nextStep = 'MVA_Contact_Information'
          break
        }

        case 'MVA_Contact_Information' : {
          nextStep = 'MVA_Last_Step'
          break
        }

        case 'MVA_Last_Step' : {
          dispatch('packageLeads')
          return
        }
      }

      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },
    packageLeads({rootState, getters, commit, dispatch}) {
      const params = extractUrlParams()
      const fields = rootState.fields
      let userAgent = 'userAgent' in navigator ? navigator.userAgent : ''

      if ("_ef_transaction_id" in params) {
        delete params['_ef_transaction_id']
      }

      const data = {
        lp_campaign_id: params['lp_campaign_id'] || fields['campaignId'] || '',
        lp_supplier_id: params['lp_supplier_id'] || fields['supplierId'] || '',
        lp_key: params['lp_key'] || fields['key'] || '',
        lp_action: params['lp_action'] || '',
        ...params,
        first_name: fields['first_name'],
        last_name: fields['last_name'],
        email: fields['email'],
        phone: fields['phone'],
        zip_code: fields['zip_code'],
        city: fields['city'],
        state: fields['state'],
        state_long: fields['state_long'],
        county: fields['county'],
        incident_date: fields['incident_date'],
        physical_injury: fields['physical_injury'],
        doctor_treatment: fields['doctor_treatment'],
        injury_type: fields['injury_type'],
        at_Fault: fields['at_Fault'],
        with_Lawyer: fields['with_Lawyer'],
        police_report: fields['police_report'],
        ef_transaction_id: fields['ef_transaction_id'],
        leadid_token: fields['jornayaId'],
        ip_address: fields['ipAddress'],
        trusted_form_cert_id: fields['trustedFormUrl'],
        accident_type: 'Car Accident',
        accident_limit_exceeded: fields['accident_limit_exceeded'],
        accident_within_last_year: fields['accident_within_last_year'],
        within_last_2_months: fields['within_last_2_months'],
        incident_occurred: fields['incident_occurred'],
        incident_timeframe: fields['incident_timeframe'],
        injury_month: fields['injury_month'],
        injury_year: fields['injury_year'],
        user_agent: userAgent,
        lp_s2: getChannel().toLowerCase(),
        page_referer: 'mva',
        oid: fields['oid'],
        affid: params.affid || 1,
        language: 'en',
        sms_verified: 'no',
        bad_words: 'no',
        enough_words: 'yes',
        is_gibberish: 'no',
        case_description: 'N/A',
        click_id: params['click_id'] || '',
        name_bad_word: fields['name_bad_word'],
        name_gibberish: fields['name_gibberish'],
        sub_id: params['sub_id'] || params['sub_id1'] || '1',
        sub_id2: params['sub_id2'] || '',
        sub_id3: params['sub_id3'] || '',
        sub_id4: params['sub_id4'] || '',
        fbclid: params['fbclid'] || fields['fbclid'] || '',
        gclid: params['gclid'] || fields['gclid'] || '',
        qualified: getters.getQualified
      }

      dispatch('sendLeads', data)
    },
    sendLeads(_, leads) {
      axios.post(
        'https://control.org31415.dev/api/leads/ingest',
        leads
      ).finally(() => {
        window.location.href = '/thank-you'
      })
    }
  }
}

export default mva