const workersComp = {
  namespaced: true,
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      /* Step Order
      * DoesUserKnowWorkPlaceInjuryWorth
      * InjuredOrDevelopedIllnessWithin2Years
      * DidInjuryOccurWhileWorking
      * DidYouSeeDoctorForInjuryAtWork
      * SelectDate
      * HasLawyer
      * CaseDescription
      * EmailAndNameInformation
      * ZipAndPhone */

      // scroll to top of page
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''

      switch(step) {
        case 'StartPath': {
          nextStep = 'DoesUserKnowWorkPlaceInjuryWorth'
          break
        }

        case 'DoesUserKnowWorkPlaceInjuryWorth': {
          nextStep = 'InjuredOrDevelopedIllnessWithin2Years'
          break
        }

        case 'InjuredOrDevelopedIllnessWithin2Years': {
          nextStep = 'DidYouSeeDoctorForInjuryAtWork'
          break
        }

        case 'DidYouSeeDoctorForInjuryAtWork': {
          nextStep = 'SelectDate'
          break
        }

        case 'SelectDate': {
          nextStep = 'HasLawyer'
          break
        }

        case 'HasLawyer': {
          nextStep = 'CaseDescription'
          break
        }

        case 'CaseDescription': {
          nextStep = 'EmailAndNameInformation'
          break
        }

        case 'EmailAndNameInformation': {
          nextStep = 'ZipAndPhone'
          break
        }

        case 'ZipAndPhone': {
          // check if user is disqualified to show disqualified component, or to redirect them to thank you page
          dispatch('postData', {}, {root: true})
          return
        }
      }
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    }
  }
}

export default workersComp
