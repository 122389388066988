import {
  extractUrlParams,
  getChannel,
  isNameGibberish
} from '@/js/utils'
import axios from 'axios'
import { blacklist } from '@/js/badwordslist'

const PATTERN_BLACK_LIST = new RegExp(`(^|\\W)(${blacklist.join('|')})($|\\W)`, 'gmi')

const stormDamage = {
  namespaced: true,
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''
      let progress = 0

      switch(step) {
        case 'StartPath': {
          nextStep = 'OwnsHome'
          progress = 24
          break
        }

        case 'OwnsHome': {
          nextStep = 'TypeOfDamageToProperty'
          progress = 38
          break
        }

        case 'TypeOfDamageToProperty': {
          nextStep = 'HasInsuranceForDamagedProperty'
          progress = 65
          break
        }

        case 'HasInsuranceForDamagedProperty': {
          nextStep = 'EmailAndNameInformation'
          progress = 78
          break
        }

        case 'EmailAndNameInformation': {
          nextStep = 'StormDamageZipAndPhone'
          break
        }

        case 'StormDamageZipAndPhone': {
          dispatch('packageLeads')
          return
        }
      }

      commit('SET_CURRENT_PROGRESS', progress, { root: true })
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },
    packageLeads({rootState, getters, commit, dispatch}) {
      const params = extractUrlParams()
      let userAgent = 'userAgent' in navigator ? navigator.userAgent : ''

      if ("_ef_transaction_id" in params) {
        //reassign key to new and delete old key
        delete params['_ef_transaction_id']
      }

      let nameGibberish = isNameGibberish(rootState.fields['firstName']) ? 'yes' : 'no'
      if (nameGibberish === 'no') {
        nameGibberish = isNameGibberish(rootState.fields['firstName']) ? 'yes' : 'no'
      }

      let nameBadWord = PATTERN_BLACK_LIST.test(rootState.fields['lastName']) ? 'yes' : 'no'
      if (nameBadWord === 'no') {
        nameBadWord = PATTERN_BLACK_LIST.test(rootState.fields['lastName']) ? 'yes' : 'no'
      }

      const data = {
        lp_campaign_id: params['lp_campaign_id'] || rootState.fields['campaignId'] || '',
        lp_supplier_id: params['lp_supplier_id'] || rootState.fields['supplierId'] || '',
        lp_key: params['lp_key'] || rootState.fields['key'] || '',
        lp_action: params['lp_action'] || '',
        ...params,
        first_name: rootState.fields['firstName'],
        last_name: rootState.fields['lastName'],
        name_gibberish: nameGibberish,
        name_bad_word: nameBadWord,
        email: rootState.fields['email'],
        phone: rootState.fields['phoneNumber'].replace(/[()\-\s]+/g, ""),
        zip_code: rootState.fields['zipCode'],
        city: rootState.fields['city'],
        state: rootState.fields['state'],
        county: rootState.fields['county'],
        sms_verified: rootState.fields['sms_verified'],
        type_of_damage_to_property: rootState.fields['type_of_damage_to_property'],
        has_insurance_for_damaged_property: rootState.fields['has_insurance_for_damaged_property'],
        owns_home: rootState.fields['owns_home'],
        user_agent: userAgent,
        trusted_form_cert_id: rootState.fields['trustedFormId'],
        trusted_form_cert_url: rootState.fields['trustedFormUrl'],
        ip_address: rootState.fields['ipAddress'],
        channel: getChannel(),
        jornayaId: rootState.fields['jornayaId'],
        ef_transaction_id: rootState.fields['ef_transaction_id'],
        referrer: 'storm-damage-flow',
        oid: rootState.fields['oid'],
        affid: params.affid || 1,
      }

      dispatch('sendLeads', data)
    },
    sendLeads(_, leads) {
      axios.post(
        'https://control.org31415.dev/api/leads/ingest',
        leads
      ).finally(() => {
        window.location.href = '/storm-ty'
      })
    }
  }
}

export default stormDamage