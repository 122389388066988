import { twilioCodePost, twilioPhonePost } from '@/js/utils'

const twilioSmsVerify = {
  namespaced: true,
  state: {
    twilio: {
      phone: '',
      sentStatus: false,
      code: '',
    },
    approved: false,
    authenticationLoading: false,
    rawPhone: '',
    toggleModalWithSmsVerification: false,
    resendCodeCoolDown: false
  },
  mutations: {
    SET_SENT_STATUS(state, status) {
      state.twilio.sentStatus = status
    },
    SET_PHONE(state, phone) {
      state.twilio.phone = phone
    },
    SET_RAW_PHONE(state, rawPhone) {
      state.rawPhone = rawPhone
    },
    SET_CODE(state, code) {
      state.twilio.code = code
    },
    TOGGLE_MODAL_WITH_SMS_VERIFICATION (state) {
      state.toggleModalWithSmsVerification = !state.toggleModalWithSmsVerification
    },
    SET_RESEND_COOLDOWN(state) {
      state.resendCodeCoolDown = true

      setTimeout(() => {
        state.resendCodeCoolDown = false
      }, 30000)
    },
    SET_AUTHENTICATION_LOADING(state, value) {
      state.authenticationLoading = value
    },
    SET_APPROVED(state, value) {
      state.approved = value
    },
    RESET_TWILIO(state) {
      state.twilio = {
        phone: '',
        sentStatus: false,
        code: '',
      }
      state.approved = false
      state.authenticationLoading = false
      state.rawPhone = ''
      state.toggleModalWithSmsVerification = false
      state.resendCodeCoolDown = false
    }
  },
  actions: {
    async postPhone({state, commit}, phone) {
      commit('SET_RAW_PHONE', phone)

      const formattedPhone = `+1${phone}`.replace(/[()\-\s]+/g, "")
      commit('SET_PHONE', formattedPhone)

      if (state.resendCodeCoolDown || state.twilio.sentStatus) return

      const { sent } = await twilioPhonePost({ phone: formattedPhone })

      commit('SET_SENT_STATUS', sent)
    },
    async resendCode({state, commit, dispatch}) {
      if (state.resendCodeCoolDown) return

      commit('SET_SENT_STATUS', false)
      dispatch('postPhone', state.rawPhone)

      commit('SET_RESEND_COOLDOWN')
    },
    async postCode({state, commit}) {
      commit('SET_AUTHENTICATION_LOADING', true)

      const approved = await twilioCodePost(state.twilio)

      if (approved) {
        commit('SET_PHONE_ERROR', false, {root: true})
        commit('TOGGLE_MODAL_WITH_SMS_VERIFICATION')
      }

      commit('SET_APPROVED', approved)
      commit('SET_AUTHENTICATION_LOADING', false)
    }
  }
}

export default twilioSmsVerify