import { getSSDIQualified } from '@/js/qualified'

const ssdi = {
  namespaced: true,
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''
      let progress = 0
      const path = window.location.pathname

      switch(step) {
        case 'StartPath':
          nextStep = 'OutOfWorkDueToADisability'
          progress = 12
          break

        case 'OutOfWorkDueToADisability':
          nextStep = 'OutOfWorkAtLeastAYear'
          progress = 25
          break

        case 'OutOfWorkAtLeastAYear':
          nextStep = 'ReceivingMedicalTreatment'
          progress = 37
          break

        case 'ReceivingMedicalTreatment':
          nextStep = 'Worked5OutOfTheLast10'
          progress = 48
          break

        case 'Worked5OutOfTheLast10':
          nextStep = 'AttorneyHelpingYouWithCase'
          progress = 55
          break

        case 'AttorneyHelpingYouWithCase':
          nextStep = 'DescribeYourIllness'
          progress = 65
          break

        case 'DescribeYourIllness':
          nextStep = 'DateOfBirth'
          progress = 75
          break

        case 'DateOfBirth':
          nextStep = 'EmailAndNameInformation'
          progress = 85
          break

        case 'EmailAndNameInformation': {
          nextStep = 'ZipAndPhone'
          progress = 96
          break
        }

        case 'ZipAndPhone': {
          // check if user is disqualified to show disqualified component, or to redirect them to thank you page
          progress = 100
          const qualified = getSSDIQualified({
            expect_to_be_out_of_work_at_least_a_year: rootState.tortData.expect_to_be_out_of_work_at_least_a_year,
            receiving_medical_treatment_for_disability: rootState.tortData.receiving_medical_treatment_for_disability,
            worked_5_of_last_10_years: rootState.tortData.worked_5_of_last_10_years,
            has_attorney: rootState.tortData.has_attorney,
            age: rootState.fields.age
          })
          commit('SET_FIELD', { field: 'qualified', value: qualified ? 'yes' : 'no' }, { root: true })

          dispatch('postData', {}, {root: true})
          return
        }
      }
      commit('SET_CURRENT_PROGRESS', progress, { root: true })
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },
  }
}

export default ssdi
