import router from '@/router'
import { ACCIDENT_LIMITS_RANGE } from '@/js/utils'
import { differenceInDays, differenceInMonths, parseISO } from 'date-fns'

const workersCompMulti = {
  namespaced: true,
  state: {
    currentQualifiedPath: 'workers-comp',
    mva_fields: {},
    camp_lejeune_fields: {}
  },
  mutations: {
    SET_CURRENT_QUALIFIED_PATH(state, qualifiedPath) {
      state.currentQualifiedPath = qualifiedPath
    },
    SET_MVA_FIELDS(state, fields) {
      //fields is type array
      fields.forEach(({ field, value }) => {
        state.mva_fields[field] = value
      })
    },
    SET_MVA_FIELD(state, { field, value }) {
      state.mva_fields[field] = value
    },
    SET_CAMP_LEJEUNE_FIELDS(state, fields) {
      //fields is type array
      fields.forEach(({ field, value }) => {
        state.camp_lejeune_fields[field] = value
      })
    },
    SET_CAMP_LEJEUNE_FIELD(state, { field, value }) {
      state.camp_lejeune_fields[field] = value
    },
  },
  getters: {
    getQualified: (state, getters, rootState) => {
      //only one of these needs to be true to get overall qualified field value
      return (!getters.getWorkersCompDisqualify || !getters.getMVADisqualify || !getters.getCampLejeuneDisqualify) ? 'yes' : 'no'
    },
    getWorkersCompDisqualify: (state, getters, rootState) => {
      const { fields, tortData } = rootState

      return (
        tortData['been_in_workplace_accident'] === 'no' ||
        tortData['did_you_see_a_doctor'] === 'no' ||
        tortData['injury_occur_while_working'] === 'no' ||
        tortData['injury_date_over_two_years'] === 'yes' ||
        fields['hasLawyer'] === 'yes' ||
        fields['isGibberish'] === 'yes' ||
        fields['enoughWords'] === 'no' ||
        fields['hasBadWords'] === 'yes' ||
        fields['name_bad_word'] === 'yes' ||
        fields['name_gibberish'] === 'yes'
      )
    },
    getMVADisqualify: (state, getters, rootState) => {
      /*
      * the only way to disqualify mva is if they answer no to not being in an accident, otherwise as described in the ticket, we will
      * show all survey/v4 questions and submit as MVA lead even if they disqualify on MVA questions
      **/

      return state.mva_fields['been_in_auto_accident'] === 'no'
    },
    getCampLejeuneDisqualify: (state, getters, rootState) => {
      return (
        state.camp_lejeune_fields['has_lawyer'] === 'yes' ||
        state.camp_lejeune_fields['on_base_between_1953_and_1987'] === 'no' ||
        state.camp_lejeune_fields['disease'] === 'none of the above'
      )
    },
    checkAndJumpToMva: (state, getters, rootState) => {
      if (getters.getWorkersCompDisqualify) {
        //update currently qualified path state field
        state.currentQualifiedPath = 'mva'
        //will return first step of MVA
        return 'BeenInAutoAccident'
      }
    },
    checkAndJumpToCampLejeune: (state, getters, rootState) => {
      if (getters.getMVADisqualify) {
        //update currently qualified path state field
        state.currentQualifiedPath = 'camp-lejeune'
        //will return first step of Camp Lejeune
        return 'ServedAtCampLejeune'
      }
    }
  },
  actions: {
    goToNextStep({ rootState, getters, commit, dispatch, state }, step) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      let nextStep = ''
      let progress = 0

      switch (step) {
        case 'StartPath': {
          nextStep = 'InjuredOrDevelopedIllnessWithin2YearsCRO'
          progress = 18
          break
        }
        //WORKERS COMP
        case 'InjuredOrDevelopedIllnessWithin2YearsCRO': {
          nextStep = getters.checkAndJumpToMva || 'SelectDate'
          progress = 35
          break
        }

        case 'SelectDate': {
          if (rootState.DQ.isDisqualified) {
            dispatch('goToDisqualifyThankYouPage')
            return
          }
          nextStep = 'DidYouSeeDoctorForInjuryAtWork'
          progress = 52
          break
        }
        case 'DidYouSeeDoctorForInjuryAtWork': {
          if (rootState.DQ.isDisqualified) {
            dispatch('goToDisqualifyThankYouPage')
            return
          }
          nextStep = 'HasLawyer'
          progress = 58
          break
        }
        case 'HasLawyer': {
          if (rootState.DQ.isDisqualified) {
            dispatch('goToDisqualifyThankYouPage')
            return
          }
          nextStep = 'CaseDescription'
          progress = 60
          break
        }
        case 'CaseDescription': {
          //if the user has made it here, that means they have qualified the workers comp path, and will be thrown into PII Collection
          nextStep = 'EmailAndNameInformation'
          progress = 84
          break
        }
        //END OF WORKERS COMP -- BEGIN MVA QUESTIONS
        case 'BeenInAutoAccident': {
          nextStep = getters.checkAndJumpToCampLejeune || 'MVA__WhenWasTheAccident'
          progress = 62
          break
        }

        case 'MVA__WhenWasTheAccident': {
          nextStep = 'MVA__AtFault'
          progress = 71
          break
        }

        case 'MVA__AtFault': {
          nextStep = 'MVA__DescribeInjuries'
          progress = 74
          break
        }

        case 'MVA__DescribeInjuries': {
          nextStep = 'MVA__PoliceReportFiled'
          progress = 79
          break
        }

        case 'MVA__PoliceReportFiled': {
          nextStep = 'MVA__HasLawyer'
          progress = 82
          break
        }

        case 'MVA__HasLawyer' : {
          nextStep = 'MVA__CaseDescription'
          progress = 87
          break
        }

        case 'MVA__CaseDescription' : {
          nextStep = 'EmailAndNameInformation'
          progress = 90
          break
        }
        //END OF MVA QUESTIONS - BEGIN CAMP LEJEUNE QUESTIONS
        case 'ServedAtCampLejeune': {
          if (rootState.DQ.isDisqualified) {
            dispatch('goToDisqualifyThankYouPage')
            return
          }
          nextStep = 'SelectMedicalCondition'
          progress = 70
          break
        }

        case 'SelectMedicalCondition': {
          nextStep = 'CampLejeuneHasAttorney'
          progress = 80
          break
        }

        case 'CampLejeuneHasAttorney': {
          if (rootState.DQ.isDisqualified) {
            dispatch('goToDisqualifyThankYouPage')
            return
          }
          nextStep = 'EmailAndNameInformation'
          progress = 90
          break
        }
        //END OF CAMP LEJEUNE QUESTIONS

        //COLLECT PII STEPS
        case 'EmailAndNameInformation': {
          if (rootState.DQ.isDisqualified && (state.currentQualifiedPath === 'workers-comp' || state.currentQualifiedPath === 'camp-lejeune')) {
            dispatch('goToDisqualifyThankYouPage')
            return
          }
          nextStep = 'MultiZipAndPhone'
          progress = 96
          break
        }
        case 'MultiZipAndPhone': {
          progress = 100
          dispatch('preparePackageData')
          return
        }
      }

      commit('SET_CURRENT_PROGRESS', progress, { root: true })
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },

    preparePackageData({state, dispatch}) {
      const qualified_path = state.currentQualifiedPath

      if (qualified_path === 'workers-comp') {
        dispatch('packageWorkersCompData')
      } else if (qualified_path === 'mva') {
        dispatch('packageMVAData')
      } else {
        dispatch('packageCampLejeuneData')
      }
    },
    packageWorkersCompData({dispatch, commit}) {
      const wcData = [
        { field: 'offer', value: 'wc' },
        { field: 'been_in_auto_accident', value: 'no' },
        { field: 'on_base_between_1953_and_1987', value: 'no' },
        { field: 'qualified', value: 'yes' }
      ]

      commit('SET_ARRAY_OF_TORT_DATA', wcData, {root: true})
      dispatch('postData', {}, {root: true})
    },
    packageMVAData({dispatch, rootState, state, commit}) {
      commit('SET_OBJECT_OF_TORT_FIELDS', state.mva_fields, {root: true})

      const stateObj = ACCIDENT_LIMITS_RANGE.find(i => i.state === rootState.fields.state_long)
      const accident_limit_exceeded = (stateObj.years * 12 < differenceInMonths(new Date(), parseISO(state.mva_fields.incident_date))) ? 1 : 0
      const accident_within_last_year = (differenceInDays(new Date(), parseISO(state.mva_fields.incident_date)) < 365) ? 'yes' : 'no'

      if (accident_limit_exceeded === 1) {
        commit('SET_DISQUALIFIED', {reason: 'accident_limit_exceeded'}, {root: true})
      }

      const mvaData = [
        { field: 'offer', value: 'aat' },
        { field: 'accident_limit_exceeded', value: accident_limit_exceeded },
        { field: 'accident_within_last_year', value: accident_within_last_year },
        { field: 'click_id', value: rootState.fields.click_id ? rootState.fields.click_id : '' },
        { field: 'gclid', value: rootState.fields.gclid ? rootState.fields.gclid : '' },
        { field: 'fbclid', value: rootState.fields.fbclid ? rootState.fields.fbclid : '' },
        { field: 'leadid_token', value: rootState.fields.jornayaId },
        { field: 'state_long', value: rootState.fields.state_long },
        { field: 'on_base_between_1953_and_1987', value: 'no' },
        { field: 'accident_type', value: 'Car Accident' },
        { field: 'qualified', value: rootState.DQ.isDisqualified ? 'no' : 'yes' }
      ]

      commit('SET_ARRAY_OF_TORT_DATA', mvaData, {root: true})
      dispatch('postData', {}, {root: true})
    },
    packageCampLejeuneData({rootState, state, commit, dispatch}) {
      commit('SET_OBJECT_OF_TORT_FIELDS', state.camp_lejeune_fields, {root: true})

      const clData = [
        { field: 'offer', value: 'cl' },
        { field: 'gclid', value: rootState.fields.gclid ? rootState.fields.gclid : '' },
        { field: 'fbclid', value: rootState.fields.fbclid ? rootState.fields.fbclid : '' },
        { field: 'been_in_auto_accident', value: 'no' },
        { field: 'qualified', value: 'yes' }
      ]

      commit('SET_ARRAY_OF_TORT_DATA', clData, {root: true})
      dispatch('postData', {}, {root: true})
    },
    goToDisqualifyThankYouPage({rootState}) {
      window.location.href = '/thank-you-dq?'
        + `_ef_transaction_id=${rootState.fields._ef_transaction_id}`
        + `&sub1=${rootState.fields.sub_id1}`
        + `&affid=${rootState.fields.affid || ''}`
        + '&q=n'
        + `&page=${window.location.pathname.replace('/', '')}`
    }
  }
}

export default workersCompMulti