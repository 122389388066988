import { postData } from '@/js/utils'
import router from '@/router'

const workersCompV3 = {
  namespaced: true,
  getters: {
    getQualified: (state, getters, rootState) => {
      return (
        rootState.tortData.injury_date_over_two_years === "no" &&
        rootState.tortData.did_you_see_a_doctor === "yes" &&
        rootState.tortData.injury_occur_while_working === "yes" &&
        rootState.fields.hasLawyer === "no" &&
        rootState.fields.isGibberish === "no" &&
        rootState.fields.enoughWords === "yes" &&
        rootState.fields.hasBadWords === "no" &&
        rootState.fields.name_bad_word === "no" &&
        rootState.fields.name_gibberish === "no"
      )
    }
  },
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''
      let progress = 0
      const path = window.location.pathname

      switch (step) {
        case 'StartPath' : {
          nextStep = 'DoesUserKnowWorkPlaceInjuryWorth'
          progress = 12
          break
        }

        case 'DoesUserKnowWorkPlaceInjuryWorth' : {
          nextStep = 'InjuredOrDevelopedIllnessWithin2Years'
          progress = 24
          break
        }

        case 'InjuredOrDevelopedIllnessWithin2Years' : {
          nextStep = 'SelectDate'
          progress = 36
          break
        }

        case 'SelectDate' : {
          nextStep = 'DidYouSeeDoctorForInjuryAtWork'
          progress = 48
          break
        }

        case 'DidYouSeeDoctorForInjuryAtWork' : {
          nextStep = 'HasLawyer'
          progress = 60
          break
        }

        case 'HasLawyer' : {
          nextStep = 'CaseDescription'
          progress = 72
          break
        }

        case 'CaseDescription' : {
          nextStep = 'EmailAndNameInformation'
          progress = 84
          break
        }

        case 'EmailAndNameInformation' : {
          nextStep = 'ZipAndPhone'
          progress = 96
          break
        }

        case 'ZipAndPhone' : {
          progress = 100
          dispatch('checkIfQualified')
          commit('SET_FIELD', { field: 'qualified', value: getters.getQualified ? 'yes' : 'no' }, { root: true })
          dispatch('postData')
          return
        }
      }
      commit('SET_CURRENT_PROGRESS', progress, { root: true })
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },
    async postData({rootState, dispatch}) {
      await postData(rootState.fields, rootState.tortData, rootState.sessionId)

      const urlParams = new URLSearchParams(location.search)
      const affid = urlParams['affid'] || ''
      const sub_id = urlParams['sub_id'] || ''
      const ef_transaction_id = urlParams['ef_transaction_id'] || urlParams['_ef_transaction_id'] || ''
      const page = rootState.page

      if (rootState.DQ.isDisqualified) {
        const reason = rootState.DQ.reason
        await router.push({
          path: '/disqualify-v2',
          query: {
            ef_transaction_id,
            affid,
            sub_id,
            reason,
          }
        })
        return
      }

      await router.push({
        path: '/thank-you-v2',
        query: {
          ef_transaction_id,
          affid,
          sub_id,
          page
        }
      })
    }
  }
}

export default workersCompV3