const dui = {
  namespaced : true,
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      window.scrollTo({top: 0, behavior: 'smooth'})

      let nextStep = ''
      let progress = 0

      switch(step) {
        case 'StartPath': {
          nextStep = 'PriorAlcoholRelatedOffense'
          progress = 12
          break
        }

        case 'PriorAlcoholRelatedOffense': {
          nextStep = 'DUIIncidentOccur'
          progress = 24
          break
        }

        case 'DUIIncidentOccur': {
          nextStep = 'TypeOfAlcoholTestPerformed'
          progress = 28
          break
        }

        case 'TypeOfAlcoholTestPerformed' : {
          if (rootState.tortData.type_of_alcohol_test_performed === 'no_test' || rootState.tortData.type_of_alcohol_test_performed === 'refused_test') {
            //set bac_level field to no_test
            rootState.tortData.bac_level = 'dont_know'
            nextStep = 'PendingDUICharges'
            progress = 36
            break
          }
          nextStep = 'BACMeasuredByTest'
          progress = 36
          break
        }

        case 'BACMeasuredByTest' : {
          nextStep = 'PendingDUICharges'
          progress = 48
          break
        }

        case 'PendingDUICharges' : {
          nextStep = 'DegreeOfInterest'
          progress = 60
          break
        }

        case 'DegreeOfInterest': {
          nextStep = 'CaseDescription'
          progress = 70
          break
        }

        case 'CaseDescription' : {
          nextStep = 'EmailAndNameInformation'
          progress = 72
          break
        }

        case 'EmailAndNameInformation' : {
          nextStep = 'ZipAndPhone'
          progress = 84
          break
        }

        case 'ZipAndPhone' : {
          progress = 100
          dispatch('postData', {}, { root: true })
          return
        }
      }
      commit('SET_CURRENT_STEP', nextStep, { root: true })
      commit('SET_CURRENT_PROGRESS', progress, { root: true })
    },
    loadMediaGravyDUIScript({ rootState }) {
      if (rootState.fields.affid === '389') {
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '813007393515258');
        fbq('track', 'PageView');
      }
    },
  }
}

export default dui