import { createApp } from 'vue'
import App from './App.vue'
import './assets/style/style.scss'
import router from "./router";
import store from './store/index'
import { install } from 'vue3-recaptcha-v2'

createApp(App)
  .use(store)
  .use(router)
  .use(install, {
    sitekey: "6LdTwDsqAAAAAAczLXrWhjVLPHmNiosCXbunox8V",
    cnDomains: false,
  })
  .mount('#app')
