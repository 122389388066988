const roundup = {
  namespaced: true,
  actions: {
    goToNextStep({rootState, getters, commit, dispatch}, step) {
      // scroll to top of page
      window.scrollTo({ top: 0, behavior: 'smooth'})

      let nextStep = ''

      switch(step) {
        case 'StartPath': {
          nextStep = 'ExposedToRoundup'
          break
        }

        case 'ExposedToRoundup': {
          nextStep = 'DiagnosisSelect'
          break
        }

        case 'DiagnosisSelect': {
          nextStep = 'DurationUseOfRoundup'
          break
        }

        case 'DurationUseOfRoundup': {
          nextStep = 'DiagnosedOneYearAfterRoundup'
          break
        }

        case 'DiagnosedOneYearAfterRoundup': {
          nextStep = 'YearOfDiagnosis'
          break
        }

        case 'YearOfDiagnosis': {
          nextStep = 'DiagnosedOverAge'
          break
        }

        case 'DiagnosedOverAge': {
          nextStep = 'LocationOfRoundupUsed'
          break
        }

        case 'LocationOfRoundupUsed': {
          nextStep = 'FarmerExposedToRoundup'
          break
        }

        case 'FarmerExposedToRoundup': {
          nextStep = 'ExposedToRoundupAfter1985'
          break
        }

        case 'ExposedToRoundupAfter1985': {
          nextStep = 'HasLawyer'
          break
        }

        case 'HasLawyer': {
          nextStep = 'CaseDescription'
          break
        }

        case 'CaseDescription': {
          nextStep = 'EmailAndNameInformation'
          break
        }

        case 'EmailAndNameInformation': {
          nextStep = 'PhoneAndAddress'
          break
        }

        case 'PhoneAndAddress': {
          dispatch('postData', {}, {root: true})
          return
        }
      }
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    }
  }
}

export default roundup