const workersCompSMSVerify = {
  namespaced: true,
  getters: {
    getQualified: (state, getters, rootState) => {
      return (
        rootState.tortData.injury_date_over_two_years === "no" &&
        rootState.tortData.did_you_see_a_doctor === "yes" &&
        rootState.tortData.injury_occur_while_working === "yes" &&
        rootState.fields.hasLawyer === "no" &&
        rootState.fields.isGibberish === "no" &&
        rootState.fields.enoughWords === "yes" &&
        rootState.fields.hasBadWords === "no" &&
        rootState.fields.name_bad_word === "no" &&
        rootState.fields.name_gibberish === "no"
      )
    }
  },
  actions: {
    goToNextStep({ rootState, getters, commit, dispatch }, step) {
      window.scrollTo({ top: 0, behavior: 'smooth' })

      let nextStep = ''
      let progress = 0

      switch(step) {
        case 'StartPath' : {
          nextStep = 'DoesUserKnowWorkPlaceInjuryWorth'
          progress = 12
          break
        }

        case 'DoesUserKnowWorkPlaceInjuryWorth' : {
          nextStep = 'InjuredOrDevelopedIllnessWithin2Years'
          progress = 24
          break
        }

        case 'InjuredOrDevelopedIllnessWithin2Years' : {
          nextStep = 'DidYouSeeDoctorForInjuryAtWork'
          progress = 36
          break
        }

        case 'DidYouSeeDoctorForInjuryAtWork' : {
          nextStep = 'SelectDate'
          progress = 48
          break
        }

        case 'SelectDate' : {
          nextStep = 'HasLawyer'
          progress = 60
          break
        }

        case 'HasLawyer' : {
          nextStep = 'CaseDescription'
          progress = 72
          break
        }

        case 'CaseDescription' : {
          nextStep = 'EmailAndNameInformation'
          progress = 85
          break
        }

        case 'EmailAndNameInformation' : {
          nextStep = 'ZipAndPhone'
          progress = 95
          break
        }

        case 'ZipAndPhone' : {
          progress = 100
          commit('SET_FIELD', { field: 'qualified', value: getters.getQualified ? 'yes' : 'no' }, { root: true })
          dispatch('postData', {}, {root: true})
          return
        }
      }

      commit('SET_CURRENT_PROGRESS', progress, { root: true })
      commit('SET_CURRENT_STEP', nextStep, { root: true })
    },
  }
}

export default workersCompSMSVerify